import React from 'react'

import './Preview.css'

export default function Preview({ url }) {
  if (url.includes('youtube.com')) {
    let id = url.split('?v=')[1]
    if (!id) return null
    return (
      <iframe
        className="Tags_Preview"
        width="100%"
        title={url}
        src={`https://www.youtube.com/embed/${id}?modestbranding=1`}
      />
    )
  }
  if (url.includes('soundcloud')) {
    return (
      <iframe
        title={url}
        allowtransparency="true"
        scrolling="no"
        frameborder="no"
        src={`https://w.soundcloud.com/player/?url=${url}&color=orange_white`}
      />
    )
  }
  return null
}
