import React from 'react'

import Preview from './Preview'
import Button from './Button'

function Row({ name, children }) {
  return (
    <div className="TrackCard_TagRow">
      <div className="TrackCard_TagName">{name}</div>
      <div className="TrackCard_TagValues">{children}</div>
    </div>
  )
}

export default function TrackCard({
  m,
  isRaw,
  onCloseClick,
  onRawResponseClick,
}) {
  let tag_data = []
  for (let key in m.native_tags) {
    let items = m.native_tags[key]
    tag_data.push(
      <Row key={key} name={key}>
        {items.join(', ')}
      </Row>,
    )
  }
  let titleNode, trackNode
  if (m.track_data === null) {
    titleNode = <div className="TrackCard_Title">External track</div>
    trackNode = null
  } else {
    let title = `${m.track_data.name} – ${m.track_data.artist}`
    titleNode = (
      <div className="TrackCard_Title" title={title}>
        {title}
      </div>
    )
    trackNode = (
      <>
        <Row name="DISCO ID">{m.track_data.id}</Row>
        <Row name="Artist">{m.track_data.artist}</Row>
        <Row name="Title"> {m.track_data.name}</Row>
        <Row name="Album">{m.track_data.album}</Row>
      </>
    )
  }

  return (
    <div className="TrackCard">
      <button
        className="TrackCard_Close"
        type="button"
        onClick={() => onCloseClick(m.message_id)}
      >
        ✕
      </button>

      {titleNode}
      {trackNode}

      <Preview url={m.source_url} />
      <Row name="URL">
        <div>
          <div className="TrackCard_Link">
            <a href={m.source_url} rel="noopener noreferrer" target="_blank">
              {m.source_url}
            </a>
          </div>
        </div>
      </Row>

      <div className="TrackCard_Spacer" />
      <Row name="BPM"> {m.bpm ? m.bpm : '-'}</Row>
      {tag_data}
      <div className="TrackCard_Footer">
        <Button
          appearance="subtle"
          type="button"
          onClick={() => onRawResponseClick(m.message_id)}
        >
          {isRaw ? 'Hide raw response' : 'Show raw response'}
        </Button>
      </div>
      <div className="TrackCard_Dump">
        <pre
          style={{ display: isRaw ? 'block' : 'none' }}
          dangerouslySetInnerHTML={{ __html: m.html_tag_data }}
        />
      </div>
    </div>
  )
}
