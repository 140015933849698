import React, { useState, useEffect, useRef, useReducer } from 'react'
import axios from 'axios'
import uuid4 from 'uuid4'

import ResultsDisplay from './components/ResultsDisplay'
import Input from './components/Input'
import Label from './components/Label'
import Button from './components/Button'

import { ReactComponent as Logo } from './assets/disco-logo.svg'
import './App.css'

const DISCO_PROD = window.location.hostname.includes('.disco.ac')
const HOST = DISCO_PROD
  ? 'https://signup.disco.ac'
  : 'https://au2.dsc.party'
const WEBSOCKET_ENDPOINT = `${HOST}/api/websocket/`
const FORM_ENDPOINT = `${HOST}/api/audio_analysis/analyze_audio/`

console.log('current endpoints', WEBSOCKET_ENDPOINT, FORM_ENDPOINT)

const CLIENT_ID = uuid4()
const CUSTOM_HEADERS = { 'X-Disco-ClientID': CLIENT_ID }

const initialState = { messages: [], processing: false }

function reducer(state, action) {
  console.log('[dispatch]', action.type, action)
  switch (action.type) {
    case 'demo_track_processed': {
      if (action.source_url !== state.processing.url) return state

      let messages = state.messages.concat(action)

      let processing = { ...state.processing }
      processing.done++
      if (processing.done === processing.count) {
        processing.step = '3_COMPLETE'
      }
      if (processing.done === 1 && !processing.count) {
        processing.step = '3_COMPLETE'
        processing.count = 1
      }

      return { ...state, processing, messages }
    }
    case 'demo_track_count': {
      let processing = { ...state.processing }
      processing.count = action.track_count
      processing.step = '2_ANALYZE_TRACKS'

      return { ...state, processing }
    }
    case 'submit/request': {
      let messages = []
      let processing = {
        url: action.url,
        done: 0,
        count: null,
        step: '0_SUBMITTING',
      }
      let serverError = null

      return { ...state, processing, messages, serverError }
    }
    case 'submit/success': {
      let processing = { ...state.processing }
      processing.step = '1_ANALYZE_URL'

      return { ...state, processing }
    }
    case 'submit/failed': {
      let serverError
      if (action.err?.response?.status === 400) {
        serverError = ERR_OUT_OF_LIMIT
      } else {
        serverError = action.err.toString()
      }
      let processing = null

      // reset form
      return { ...state, processing, serverError }
    }
    default: {
      return state
    }
  }
}

function Status({ state, messagesHidden }) {
  let { processing } = state

  if (!processing) return null
  let { count, done, step } = processing

  if (step === '0_SUBMITTING') return null

  if (step === '1_ANALYZE_URL') {
    return null
  }
  if (step === '2_ANALYZE_TRACKS') {
    return (
      <div className="Tags_ResultsTitle">
        <div>
          <div>
            <span className="Tags_Number">
              {done}/{count}
            </span>{' '}
            tracks analyzed
          </div>
          <Label>Analyzing playlist...</Label>
        </div>
        <div className="Tags_ResultsRowEnd" />
      </div>
    )
  }
  if (step === '3_COMPLETE') {
    return (
      <div className="Tags_ResultsTitle">
        <div>
          <div>
            <span className="Tags_Number">
              {done}/{count}
            </span>{' '}
            tracks analyzed
          </div>
          <Label>&nbsp;</Label>
        </div>
      </div>
    )
  }
  return null
}

function checkLimits() {
  let l = { v: 0, d: +new Date() }
  try {
    let n = JSON.parse(localStorage.l)
    if (new Date() - n.d < 14 * 24 * 3600 * 1000) l = n
  } catch (err) {}
  if (l.v++ < 30) {
    localStorage.l = JSON.stringify(l)
    return null
  } else {
    return ERR_OUT_OF_LIMIT
  }
}
const ERR_OUT_OF_LIMIT = 'Out of limit'

function AlertBox({ alertBox, serverError }) {
  let error = alertBox || serverError
  if (error === ERR_OUT_OF_LIMIT) {
    return (
      <Label alert>
        To tag more tracks,{' '}
        <a target="_blank" href="mailto:support@disco.ac">
          contact us here
        </a>
        .
      </Label>
    )
  }
  if (!error) return null
  return <Label alert>{error}</Label>
}
function Tags(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [alertBox, setAlertBox] = useState('')
  const [url, setUrl] = useState(null)
  const [rawDataVisible, setRawDataVisible] = useState({})
  const [messagesHidden, setMessagesHidden] = useState({})

  const onRawResponseClick = messageId => {
    setRawDataVisible(r => ({ ...r, [messageId]: !r[messageId] }))
  }

  const onCloseClick = messageId => {
    setMessagesHidden(m => ({ ...m, [messageId]: true }))
  }

  const fetchGalibooResponse = useRef(() => {})
  useEffect(() => {
    fetchGalibooResponse.current = async () => {
      try {
        let response = await axios.post(
          WEBSOCKET_ENDPOINT,
          {},
          { headers: CUSTOM_HEADERS },
        )
        const arrivedMessages = response.data.messages
        arrivedMessages.forEach(m => dispatch(m))
      } catch (err) {
        console.log('Websocket POST Err', err)
      }
    }
  })

  useEffect(() => {
    let intvl = setInterval(() => fetchGalibooResponse.current(), 1_000)
    return () => clearInterval(intvl)
  }, [])

  const onSubmit = async evt => {
    if (evt) evt.preventDefault()
    setAlertBox()
    // validate: url
    if (!url) {
      setAlertBox('Please fill in url!')
      return
    }

    dispatch({ type: 'submit/request', url })

    let outOfLimit = checkLimits()
    if (outOfLimit) {
      setTimeout(() => {
        dispatch({
          type: 'submit/failed',
          url,
          err: { response: { status: 400 } },
        })
      }, 1100 + Math.random() * 2200)
      return
    }

    try {
      const response = await axios.post(
        FORM_ENDPOINT,
        { audio_url: url },
        { headers: CUSTOM_HEADERS },
      )
      console.log(response)
      dispatch({ type: 'submit/success', url })
    } catch (err) {
      if (err.response?.status === 400) {
        let msg = err.response?.data[0]
        if (typeof msg === 'string') setAlertBox(msg)
      }

      dispatch({ type: 'submit/failed', url, err })
    }
  }

  // render
  return (
    <div>
      <h1 className="Tags_TitleBar">
        <div className="Tags_Cont">
          <div className="Tags_Title">
            <Logo />
            <div className="Tags_Title_Marker">&middot;</div>
            <div>Auto-Tagging Demo</div>
          </div>
        </div>
      </h1>
      <form className="Tags_Cont" onSubmit={onSubmit}>
        <Label className="Tags_Label" htmlFor="url">
          Enter URL:
        </Label>
        <div className="Tags_Form">
          <Input
            id="url"
            autoFocus
            type="text"
            autoComplete="off"
            name="url"
            onChange={e => setUrl(e.target.value)}
            value={url || ''}
            placeholder="Soundcloud / YouTube / DISCO URL"
          />
          <Button
            type="submit"
            disabled={state.processing?.step === '0_SUBMITTING'}
          >
            Get tags
          </Button>
        </div>
        <AlertBox alertBox={alertBox} serverError={state.serverError} />

        <Label>
          {state.processing?.step === '0_SUBMITTING' && <>Submitting...</>}
          {state.processing?.step === '1_ANALYZE_URL' && <>Processing...</>}
        </Label>
      </form>
      <div className="Tags_Cont">
        <Status state={state} messagesHidden={messagesHidden} />
        <div className="Tags_ResultsGrid">
          <ResultsDisplay
            messages={state.messages}
            messagesHidden={messagesHidden}
            rawDataVisible={rawDataVisible}
            onRawResponseClick={onRawResponseClick}
            onCloseClick={onCloseClick}
          />
        </div>
      </div>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <Tags />
    </div>
  )
}

export default App
