import React from 'react'

import './Button.css'

export default function Button({ appearance = 'primary', ...props }) {
  return (
    <button
      {...props}
      className="Tags_Button"
      data-appearance={appearance}
    />
  )
}
