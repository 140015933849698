import React from 'react'

import TrackCard from './TrackCard'

export default function ResultsDisplay(props) {
  const {
    messages,
    messagesHidden,
    rawDataVisible,
    onRawResponseClick,
    onCloseClick,
  } = props
  let ret = []

  for (let m of messages) {
    if (m.type === 'demo_track_processed') {
      let isRaw = rawDataVisible[m.message_id]

      if (messagesHidden[m.message_id]) {
        continue
      }

      ret.push(
        <TrackCard
          key={m.message_id}
          m={m}
          isRaw={isRaw}
          onCloseClick={onCloseClick}
          onRawResponseClick={onRawResponseClick}
        />,
      )
    }
  }

  return ret
}
